import React from 'react';
import './TnCPage.css'; // Make sure to create this CSS file

const TnCPage = () => {
    return (
        <div className='pagewrapper'>
            <div className='background-wrapper tncwrapper'>
                <h1 className='tnc-header'>Terms and Conditions</h1>
                <h2>Last Updated: 12/5/2023</h2>
                <div className='terms-wrapper'>
                <div className='terms-content'>
                    <h3>1. Acceptance of Terms</h3>
                    <p>Welcome to GreenMind.Studio. By accessing or using our web application, you agree to be bound by these Terms and Conditions ("Terms") and our Privacy Policy. GreenMind.Studio is a digital platform designed to revolutionize cannabis retail by providing sophisticated software solutions for operations management, customer experience enhancement, and strategic inventory management.</p>

                    <h3>2. Use of Our Service</h3>
                    <p>GreenMind.Studio offers various services, including but not limited to digital signage control, campaign tracking, reporting automation, POS synchronization, and other related digital solutions for cannabis retail businesses. These services are intended for lawful use by businesses in compliance with applicable laws and regulations.</p>

                    <h3>3. User Accounts and Data</h3>
                    <p>To access certain features of our service, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. GreenMind.Studio reserves the right to suspend or terminate your account if any information provided during the registration process or thereafter is inaccurate, not current, or incomplete.</p>

                    <h3>4. API Key Usage</h3>
                    <p>a. Provision of API Key: You may choose to provide your internal Dutchie API key to utilize specific functionalities of our service. By providing your API key, you acknowledge that you do so at your own risk.</p>
                    <p>b. Data Access and Use: You acknowledge that the API key may allow access to sensitive data. GreenMind.Studio will not access or use this data except as necessary to provide the services or as required by law.</p>

                    <h3>5. Intellectual Property Rights</h3>
                    <p>All content on GreenMind.Studio, including but not limited to software, graphics, text, and logos, is the property of GreenMind.Studio or its licensors and is protected by copyright and other intellectual property laws. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without express written permission by us.</p>

                    <h3>6. Prohibited Conduct</h3>
                    <p>You agree not to use the service for any unlawful purpose, in any way that interrupts, damages, or impairs the service, or in any manner inconsistent with these Terms.</p>

                    <h3>7. Disclaimer of Warranties</h3>
                    <p>Our service is provided "as is" and "as available" without warranties of any kind, either express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement.</p>

                    <h3>8. Limitation of Liability</h3>
                    <p>In no event will GreenMind.Studio, its affiliates, agents, directors, employees, suppliers, or licensors be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the service; (ii) any conduct or content of any third party on the service; (iii) any content obtained from the service; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

                    <h3>9. Indemnification</h3>
                    <p>You agree to defend, indemnify, and hold harmless GreenMind.Studio and its licensee and licensors, and their employees, contractors, agents, officers, and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password; b) a breach of these Terms, or c) Content posted on the Service.</p>

                    <h3>10. Modifications to Terms</h3>
                    <p>We reserve the right to modify or replace these Terms at any time at our sole discretion. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

                    <h3>11. Governing Law</h3>
                    <p>These Terms shall be governed and construed in accordance with the laws of Michigan, United States, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>

                    <h3>12. Contact Information</h3>
                    <p>If you have any questions about these Terms, please contact us at support@greenmind.studio.</p>
                </div>
            </div>
            </div>
        </div>
    );
};

export default TnCPage;
