// ReorderReport.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import VendorList from './VendorList';
import AppHeader from './AppHeader';
import './ReorderReport.css';

const ReorderReport = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const jwt = localStorage.getItem('token');

  // Utility function to format date in local timezone
  const toLocalISOString = (date) => {
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
    return adjustedDate.toISOString().slice(0, 16);
  };

  const [startDate, setStartDate] = useState(toLocalISOString(new Date(new Date().setMonth(new Date().getMonth() - 2))));
  const [endDate, setEndDate] = useState(toLocalISOString(new Date()));

  const [totalCost, setTotalCost] = useState(0);
  const [remainingCost, setRemainingCost] = useState(0);

  // Callback to update the totals
  const handleTotalsUpdate = (total, remaining) => {
    setTotalCost(total);
    setRemainingCost(remaining);
  };

  useEffect(() => {
    const fetchInventoryData = async () => {

      setInventoryData([]);
      // Extracting the selectedStore ID from localStorage
        const rawStore = localStorage.getItem('selectedStore');
        const store = rawStore ? JSON.parse(rawStore) : null;
        const selectedStoreId = store ? store.id : null;

        // Handling the case where the selectedStoreId is not available
        if (!selectedStoreId) {
        console.error('Selected store ID not found in local storage');
        // Here you can redirect the user to select a store or show an error message
        }

        const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'Selected-Store-Id': selectedStoreId, // Passing the store ID in headers
        },
        };

        let response;
        try {
          response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get_received_inventory?startDate=${startDate}&endDate=${endDate}`, requestOptions);
          
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();
          setInventoryData(data);
        } catch (error) {
          console.error('Error fetching inventory data:', error);
          if (response && response.status === 401) {
            navigate('/login');
          }
        } finally {
          setLoading(false);
        }
      };
    
      fetchInventoryData();
    }, [startDate, endDate, navigate, jwt]);

  if (loading) {
    return <div class="loader-container">
        <div class="loader-div"></div>
    </div>
    ;
  }

  return (
    <div className='background-wrapper reorder-wrapper'>
    <AppHeader
            showBackButton={true}
            showSettings={true}
            showLogout={true}
            logoUrl="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png"
        />
    <div className="reorder-report">
      
      <div className="date-picker-container">
      <h1 className="title">Supply Chain</h1>
      {/* Add the nested divs here */}
      <div className="totals-container">
          <div className="total-cost">Total Cost of Visible Orders: ${totalCost.toLocaleString()}</div>
          <div className="remaining-cost">Remaining Cost of Visible Orders: ${remainingCost.toLocaleString()}</div>
        </div>
      <div className="date-picker-container">
          <div className="date-picker-item">
            <label htmlFor="start-date" className="date-label">Start Date:</label>
            <input id="start-date" type="datetime-local" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </div>
          <div className="date-picker-item">
            <label htmlFor="end-date" className="date-label">End Date:</label>
            <input id="end-date" type="datetime-local" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </div>
        </div>
        </div>
      <VendorList inventoryData={inventoryData} onTotalsUpdate={handleTotalsUpdate} />
    </div>
    </div>
  );
};

export default ReorderReport;
