import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import './Settings.css';

const Settings = () => {
    const navigate = useNavigate();

    const [zoomStates, setZoomStates] = useState({
        'settings-inset-div1': { scale: 1, x: 50, y: 50 },
        'settings-inset-div2': { scale: 1, x: 50, y: 50 },
        'settings-inset-div3': { scale: 1, x: 50, y: 50 },
        'settings-inset-div4': { scale: 1, x: 50, y: 50 },
      });
  
      const handleMouseEnter = (divId) => {
        setZoomStates(prevZoomStates => ({
          ...prevZoomStates,
          [divId]: { ...prevZoomStates[divId], scale: 1.15 },
        }));
      };
      
      const handleMouseMove = (divId, e) => {
        const div = e.currentTarget;
        const { left, top, width, height } = div.getBoundingClientRect();
        const x = ((e.pageX - left) / width) * 100;
        const y = ((e.pageY - top) / height) * 100;
        setZoomStates(prevZoomStates => ({
          ...prevZoomStates,
          [divId]: { ...prevZoomStates[divId], x, y },
        }));
      };
      
      const handleMouseLeave = (divId) => {
        setZoomStates(prevZoomStates => ({
          ...prevZoomStates,
          [divId]: { scale: 1, x: 50, y: 50 },
        }));
      };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/login');
    }

    const handleBack = () => {
        navigate('/dashboard');
    }

    const handleStoreSettings = () => {
        navigate('/storespage');
    }

    const handleQueueSettings = () => {
        // Redirect to the respective settings page.
        navigate('/QueueSettingsPage');
    }

    const handleOtherSettings2 = () => {
        // Redirect to the respective settings page.
        navigate('/StoreSubscriptions');
    }

    const handleOtherSettings3 = () => {
        // Redirect to the respective settings page.
        navigate('/otherSettings3');
    }

    const handleManageProfiles = () => {
        navigate('/profile-manager');
    }

    return (
        <div className="settings-div-container">
            <div className="settings-container">
                <div className="logo-div">
                <Link to="/dashboard">
                    <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSettingsLogo.png" alt="Logo" className="settings-logo" />
                    </Link>
                </div>
                <div className="settings-buttons-div">
                    <button onClick={handleBack}>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
                    </button>
                    <button onClick={handleLogout}>
                        <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
                    </button>
                </div>
            </div>

            <div className="settings-grid">
                <div className="settings-quarter-div">
                <div
          className="inset-div settings-inset-div1"
          onMouseEnter={() => handleMouseEnter('settings-inset-div1')}
          onMouseMove={(e) => handleMouseMove('settings-inset-div1', e)}
          onMouseLeave={() => handleMouseLeave('settings-inset-div1')}
          onClick={handleStoreSettings}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/SettingsScreenImg1.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['settings-inset-div1'].scale})`,
        transformOrigin: `${zoomStates['settings-inset-div1'].x}% ${zoomStates['settings-inset-div1'].y}%`
      }}
    />
                        <span className="settings-btnlabel">STORE SETTINGS</span>
                    </div>
                </div>
                <div className="settings-quarter-div">
                <div
          className="inset-div settings-inset-div2"
          onMouseEnter={() => handleMouseEnter('settings-inset-div2')}
          onMouseMove={(e) => handleMouseMove('settings-inset-div2', e)}
          onMouseLeave={() => handleMouseLeave('settings-inset-div2')}
          onClick={handleQueueSettings}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/SettingsScreenImg2.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['settings-inset-div2'].scale})`,
        transformOrigin: `${zoomStates['settings-inset-div2'].x}% ${zoomStates['settings-inset-div2'].y}%`
      }}
    />
                        <span className="settings-btnlabel">QUEUE SETTINGS</span>
                    </div>
                </div>
                <div className="settings-quarter-div">
                <div
          className="inset-div settings-inset-div1"
          onMouseEnter={() => handleMouseEnter('settings-inset-div3')}
          onMouseMove={(e) => handleMouseMove('settings-inset-div3', e)}
          onMouseLeave={() => handleMouseLeave('settings-inset-div3')}
          onClick={handleOtherSettings2}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/SettingsScreenImg3.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['settings-inset-div3'].scale})`,
        transformOrigin: `${zoomStates['settings-inset-div3'].x}% ${zoomStates['settings-inset-div3'].y}%`
      }}
    />
                        <span className="settings-btnlabel">SUBSCRIPTIONS</span>
                    </div>
                </div>
                <div className="settings-quarter-div">
                <div
          className="inset-div settings-inset-div1"
          onMouseEnter={() => handleMouseEnter('settings-inset-div4')}
          onMouseMove={(e) => handleMouseMove('settings-inset-div4', e)}
          onMouseLeave={() => handleMouseLeave('settings-inset-div4')}
          onClick={handleManageProfiles}
        >
          <img
      className="background-image"
      src="https://d27oebqltxtb0s.cloudfront.net/SettingsScreenImg4.png"
      alt="TV Slideshow"
      style={{
        transform: `scale(${zoomStates['settings-inset-div4'].scale})`,
        transformOrigin: `${zoomStates['settings-inset-div4'].x}% ${zoomStates['settings-inset-div4'].y}%`
      }}
    />
                        <span className="settings-btnlabel">MANAGE PROFILES</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Settings;
