import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import './EditUserPage.css';

function EditUserPage(props) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const userId = searchParams.get('userId');

    console.log("Extracted userId:", userId);
    

    const [user, setUser] = useState({});
    const [associatedStores, setAssociatedStores] = useState([]);
    const [allStores, setAllStores] = useState([]);
    const [selectedStoreToAdd, setSelectedStoreToAdd] = useState(null);

    const [isAdmin, setIsAdmin] = useState(false);
    const [allowedPages, setAllowedPages] = useState({
        healthCheck: false,
        slideshows: false,
        dealsheets: false,
        customerMenu: false,
        queue: false,
        qrTracker: false,
        reorderReport: false,
    });

    useEffect(() => {

        // Fetch the user's data when the component mounts
        const fetchUserData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/current_user/?user_id=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();

                setUser({
                    name: `${data.first_name} ${data.last_name}`,
                    id: data.user_id
                });

                setIsAdmin(data.is_admin);
                setAllowedPages({
                    healthCheck: data.can_access_health_check,
                    slideshows: data.can_access_slideshows,
                    dealsheets: data.can_access_dealsheets,
                    customerMenu: data.can_access_customer_menu,
                    queue: data.can_access_queue,
                    qrTracker: data.can_access_qr_tracker,
                    reorderReport: data.can_access_reorder_report,
                });

            } catch (error) {
                console.error('There was a problem with the fetch operation:', error.message);
            }
        };

        fetchUserData();
        
        // Fetch user details
        console.log("TEST");
    
        const url = `${process.env.REACT_APP_API_BASE_URL}/users/users/${userId}/`;
        console.log("URL being fetched:", url);
    
        const token = localStorage.getItem('token');
        console.log("Token:", token);
    
        fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(data => {
            console.log("Server response:", data);
            setUser({
                name: `${data.first_name} ${data.last_name}`,
                id: data.user_id
            });
        })
        .catch(error => {
            console.error("Error message:", error.message);
            console.error("Error object:", error);
        });



        // Fetch all stores for the user
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            setAllStores(data);
        })
        .catch(error => {
            console.error("Error fetching stores:", error);
        });

        // Fetch stores associated with the user
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users/${userId}/stores/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => {
            setAssociatedStores(data.stores);
        })
        .catch(error => {
            console.error("Error fetching associated stores:", error);
        });
    }, [userId]);

    // Check if store is associated with user
    const isStoreAssociated = (storeId) => {
        return associatedStores.some(store => store.id === storeId);
    };

    // Handle adding/removing store association
    const handleStoreCheckboxChange = (storeId) => {
        if (isStoreAssociated(storeId)) {
            // Logic to remove user from the store
            handleRemoveFromStore(storeId);
        } else {
            // Logic to add user to the store
            handleAddToStore(storeId);
        }
    };
    

    function handleDisableUser() {
        const token = localStorage.getItem('token');
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users/disable/${userId}/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then(data => {
            if (data.message) {
                console.log("User disabled successfully:", data.message);
                // Redirect or update UI as needed
            } else {
                console.error("Error disabling user:", data.error);
            }
        })
        .catch(error => {
            console.error("Error disabling user:", error);
        });
    }
    
    

    function handleAddToStore(storeId) {
        const token = localStorage.getItem('token');
    
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users/${userId}/stores/${storeId}/add`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(data => {
            if (data.message) {
                setAssociatedStores(prevStores => [...prevStores, { id: storeId }]);
            } else {
                console.error("Error adding user to store:", data.error);
            }
        })
        .catch(error => {
            console.error("Error adding user to store:", error);
        });
    }
    
    function handleRemoveFromStore(storeId) {
        const token = localStorage.getItem('token');
    
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/users/${userId}/stores/${storeId}/remove`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        .then(res => res.json())
        .then(data => {
            if (data.message) {
                setAssociatedStores(prevStores => prevStores.filter(store => store.id !== storeId));
            } else {
                console.error("Error removing user from store:", data.error);
            }
        })
        .catch(error => {
            console.error("Error removing user from store:", error);
        });
    }
    

    // Function to call the set_permissions endpoint
    const updatePermissions = (userId, newPermissions) => {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/set_permissions/${userId}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(newPermissions)
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log("Permissions updated: ", data.message);
            // Optionally update state or UI here
        })
        .catch(error => console.error('Error updating permissions:', error));
    };

    // Call updatePermissions when isAdmin changes
    const handleIsAdminChange = (event) => {
        setIsAdmin(event.target.checked);
        updatePermissions(userId, {
            is_admin: event.target.checked,
            ...allowedPages
        });
    };

    // Call updatePermissions when allowed pages change
    const handleAllowedPageChange = (page) => {
        const updatedPages = {
            ...allowedPages,
            [page]: !allowedPages[page],
        };
        setAllowedPages(updatedPages);
        updatePermissions(userId, {
            is_admin: isAdmin,
            ...updatedPages
        });
    };

    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmDisable = () => {
        // Call the function to disable the user here
        handleDisableUser();
        handleCloseModal();
    };
    

    return (
        <div className='pagewrapper'>
        <div className='background-wrapper'>
        <div className="edit-user-container">
            <h2>Edit User: {user.name}</h2>
            <button onClick={handleOpenModal}>Delete User</button>
            <label>
                    <input 
                        type="checkbox"
                        checked={isAdmin}
                        onChange={handleIsAdminChange}
                    />
                    Is Admin
                </label>
            <h3>Stores</h3>
            <ul>
                {allStores.map(store => (
                    <li key={store.id}>
                        <input 
                            type="checkbox"
                            checked={isStoreAssociated(store.id)}
                            onChange={() => handleStoreCheckboxChange(store.id)}
                        />
                        {store.name}
                    </li>
                ))}
            </ul>

            <div className="user-privileges">
                

                <h3>Allowed Pages</h3>
                {Object.entries(allowedPages).map(([page, isAllowed]) => (
                    <label key={page}>
                        <input 
                            type="checkbox"
                            checked={isAllowed}
                            onChange={() => handleAllowedPageChange(page)}
                        />
                        {page.charAt(0).toUpperCase() + page.slice(1)} {/* Capitalize page name */}
                    </label>
                ))}
            </div>
        </div>
        <ConfirmationModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                onConfirm={handleConfirmDisable}
                title="Confirm Delete User"
                message="Are you sure you want to delete this user?"
                confirmButtonText="Yes, Delete"
            />
        </div>
        </div>
    );
}

export default EditUserPage;
