import React, { useState, useEffect } from 'react';
import EditStoreModal from './EditStoreModal';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import ConfirmationModal from './ConfirmationModal';
import SecureConfirmationModal from './SecureConfirmationModal';

import './StoresPage.css';

function StoresPage() {

    const navigate = useNavigate();
    const [stores, setStores] = useState([]);
    const [userId, setUserId] = useState(null);
    const [isEditModalVisible, setEditModalVisible] = useState(false);
    const [currentStore, setCurrentStore] = useState(null);

    const [isConfModalVisible, setConfModalVisible] = useState(false);
    const [isSecureConfModalVisible, setSecureConfModalVisible] = useState(false);

    const [storeToDelete, setStoreToDelete] = useState(null);

    useEffect(() => {
        // Fetch the list of stores directly
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        .then(res => res.json())
        .then(data => setStores(data))
        .catch(error => console.error("Error fetching stores:", error));
    }, []);

    const handleAddStore = () => {
        // Making an API call to create a new store with default values
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                name: 'New Store',
                logo_url: 'https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png', // Placeholder logo URL
                dutchie_api_key: 'placeholder_api_key', // Placeholder API Key, update this as necessary
                // ... other default values
            })
        })
        .then(res => res.json())
        .then(data => {
            console.log(data);
            // Update the local state with the new store
            setStores(prevStores => [...prevStores, data]);
        })
        .catch(error => console.error("Error adding store:", error));
    };

    const initiateSecureDeletion = () => {
        setConfModalVisible(false); // Close the first confirmation modal
        setSecureConfModalVisible(true); // Open the secure confirmation modal
    };

    const handleSecureDeleteStore = () => {
        if (storeToDelete) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${storeToDelete.id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Selected-Store-Id': `${storeToDelete.id}`
                }
            })
            .then(() => {
                // Filtering out the deleted store from the state
                setStores(prevStores => prevStores.filter(store => store.id !== storeToDelete.id));
                // Reset the deletion states
                setStoreToDelete(null);
                // IMPORTANT: Close both modals
                setSecureConfModalVisible(false); // Make sure this line is included to close the SecureConfirmationModal
                console.log('Store deleted successfully');
            })
            .catch(error => {
                console.error("Error deleting store:", error);
            });
        }
    };
    
    const handleStoreDeletionRequest = (storeId) => {
        const store = stores.find(s => s.id === storeId);
        setStoreToDelete(store);
        setConfModalVisible(true);
    };

      
      const handleCancelDelete = () => {
        setConfModalVisible(false);
      };

    const handleBack = () => {
        navigate('/dashboard');
    }


    const handleEditStore = (storeId) => {
        const storeToEdit = stores.find(store => store.id === storeId);
        setCurrentStore(storeToEdit);
        setEditModalVisible(true);
    };

    const handleUpdateStore = (updatedStore) => {
        // Make an API call to update the store
        // On success, update the local state and close the modal
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/stores/${updatedStore.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(updatedStore)
        })
        .then(res => res.json())
        .then(data => {
            setStores(prevStores => prevStores.map(store => {
                if (store.id === updatedStore.id) {
                    return updatedStore;  // Return the updated store object with the new logo URL
                }
                return store;
            }));
            setEditModalVisible(false);
        })
        .catch(error => console.error("Error updating store:", error));
    };

    return (
        <div className="stores-page">
            <div className="header">
                <div className='dash-logo'>
                <Link to="/dashboard">
                <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png" alt="Logo" className="dash-logo" />
                </Link>
                </div>
                <button className="btn-back" onClick={handleBack}>
                    <i class="fa fa-arrow-left" aria-hidden="true"></i> Back
                </button>
                <button className="btn-add" onClick={handleAddStore}>
                    <i class="fa fa-plus" aria-hidden="true"></i> New Store
                </button>
            </div>  e
            <div className="stores-list-container">

            <div className="stores-list">
                {stores.map(store => (
                    <div key={store.id} className="store-row">
                        <img src={store.logo_url} alt={store.name} className="store-logo" />
                        <span className="store-name">{store.name}</span>
                        <button className="btn-edit" onClick={() => handleEditStore(store.id)}>
                            <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                        </button>
                        <button className="btn-delete" onClick={() => handleStoreDeletionRequest(store.id)}>

                            <i class="fa fa-trash-o" aria-hidden="true"></i> Delete
                        </button>
                    </div>
                ))}
            </div>
            </div>
        {isEditModalVisible && 
            <div className="modal-background">
                <div className="sheet-modal-content">
                    <EditStoreModal 
                        store={currentStore} 
                        onClose={() => setEditModalVisible(false)} 
                        onSave={handleUpdateStore}
                    />
                </div>
            </div>
        }
        {isConfModalVisible && 
                <ConfirmationModal
                    isOpen={isConfModalVisible}
                    onClose={handleCancelDelete}
                    onConfirm={initiateSecureDeletion}
                    title="Confirm Deletion"
                    message={`Are you sure you want to delete the store "${storeToDelete?.name}"?`}
                />
            }
        {isSecureConfModalVisible && 
            <SecureConfirmationModal
                isOpen={isSecureConfModalVisible}
                onClose={() => setSecureConfModalVisible(false)}
                onConfirm={handleSecureDeleteStore}
                title="Are You Sure?"
                message={`This action is permanent and cannot be undone. Type "permanently delete" to confirm.`}
            />
        }

        </div>
        
    );
}

export default StoresPage;
