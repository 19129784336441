import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './StoreSelectionPage.css';
import './EditAppDevicePage.css';
import AppHeader from './AppHeader';
import ConfirmationModal2 from './ConfirmationModal2';

function EditAppDevicePage() {
    const navigate = useNavigate();
    const [appDevices, setAppDevices] = useState([]);
    const [slideshows, setSlideshows] = useState([]);
    const selectedStore = JSON.parse(localStorage.getItem('selectedStore'));
    const [loading, setLoading] = useState(true);
    const [deviceCode, setDeviceCode] = useState('');
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deviceToDelete, setDeviceToDelete] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedStore) {
                try {
                    await fetchAppDevices(selectedStore.id);
                    await fetchSlideshows(selectedStore.id);
                } catch (error) {
                    setError('Failed to fetch data: ' + error.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchData();
    }, [selectedStore.id]);

    const fetchAppDevices = async (storeId) => {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/app-devices/get-app-devices/?store_id=${storeId}`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
        });
        if (!response.ok) throw new Error('Failed to fetch app devices');
        const data = await response.json();
        setAppDevices(data.sort((a, b) => {
            const nameA = a.name || ''; // Default to empty string if name is null
            const nameB = b.name || '';
            return nameA.localeCompare(nameB, undefined, { numeric: true, sensitivity: 'base' });
        }));
    };

    const fetchSlideshows = async (storeId) => {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/`, {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Selected-Store-Id': storeId },
        });
        if (!response.ok) throw new Error('Failed to fetch slideshows');
        setSlideshows(await response.json());
    };

    const registerAppDevice = async () => {
        if (!deviceCode) {
            alert("Device code is required.");
            return;
        }
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/app-devices/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ device_code: deviceCode, store: selectedStore.id })
        });
        if (!response.ok) {
            const errorData = await response.json();
            alert(`Failed to register app device: ${errorData.error}`);
            return;
        }
        alert('Device registered successfully');
        setDeviceCode(''); // Clear input after successful registration
        fetchAppDevices(selectedStore.id); // Refresh list to show updated data
    };

    const updateAppDevice = async (deviceId, updates) => {
        const deviceToUpdate = appDevices.find(device => device.id === deviceId);
        const updatedDevice = { ...deviceToUpdate, ...updates };
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/app-devices/register/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(updatedDevice)
        });
        if (!response.ok) {
            const errorData = await response.json();
            console.error(`Failed to update app device: ${errorData.error}`);
            return;
        }
        fetchAppDevices(selectedStore.id); // Refresh the list to show the updated device
    };

    const deleteAppDevice = async (deviceId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/app-devices/delete/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify({ id: deviceId })
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(`Failed to delete app device: ${errorData.error || 'Unknown error'}`);
            }
            // Refetch devices to reflect changes
            setAppDevices(appDevices.filter(device => device.id !== deviceId));
        } catch (error) {
            console.error("Error deleting app device:", error);
        }
    };

    const handleDeleteClick = (deviceId) => {
        setDeviceToDelete(deviceId);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        if (deviceToDelete) {
            deleteAppDevice(deviceToDelete);
            setDeviceToDelete(null);
            setIsModalOpen(false);
        }
    };

    const handleCancelDelete = () => {
        setDeviceToDelete(null);
        setIsModalOpen(false);
    };

    const handleDeviceTypeChange = (deviceId, newType) => {
        updateAppDevice(deviceId, { type: newType, target: newType === 'slideshow' ? null : undefined });
    };

    const handleDeviceNameChange = (event, deviceId) => {
        const updatedAppDevices = appDevices.map(device => {
            if (device.id === deviceId) {
                return { ...device, name: event.target.value };
            }
            return device;
        });
        setAppDevices(updatedAppDevices);
    };

    const handleSlideshowChange = (deviceId, slideshowId) => {
        updateAppDevice(deviceId, { target: parseInt(slideshowId, 10) });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="appdevice-wholeDeviceAppPageContainer">
            <AppHeader
                showBackButton={true}
                showSettings={true}
                showLogout={true}
                logoUrl="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png"
            />
            <div className="appdevice-adddevicepageedits appdevice-store-selection-page2">
                <div className='appdevice-appdevicepageedits appdevice-tv-wrapper'>
                    <div className="appdevice-new-device-input">
                        <input
                            type="text"
                            placeholder="Enter Device Code"
                            value={deviceCode}
                            onChange={e => setDeviceCode(e.target.value)}
                            className="appdevice-input-field"
                        />
                        <button className="appdevice-btn-add" onClick={registerAppDevice}>Register Device</button>
                    </div>
                    <div className="appdevice-scrollable-box">
                        {appDevices.map(device => (
                            <div key={device.id} className="appdevice-device-item-wrapper">
                                <div className="appdevice-device-item">
                                    <input
                                        type="text"
                                        value={device.name || ''}
                                        onChange={(e) => handleDeviceNameChange(e, device.id)}
                                        onBlur={() => updateAppDevice(device.id, { name: device.name })}
                                        placeholder={`Device Name (${device.device_code})`}
                                        className="appdevice-input-field"
                                    />
                                    <select
                                        value={device.type || ''}
                                        onChange={(e) => handleDeviceTypeChange(device.id, e.target.value)}
                                        className="appdevice-btn-select"
                                    >
                                        <option value="">Select Type</option>
                                        <option value="slideshow">Slideshow</option>
                                        <option value="queue">Queue</option>
                                    </select>
                                    {device.type === 'slideshow' && (
                                        <select
                                            value={device.target || ''}
                                            onChange={(e) => handleSlideshowChange(device.id, e.target.value)}
                                            className="appdevice-btn-select-slideshow"
                                        >
                                            <option value="">Choose Slideshow</option>
                                            {slideshows.map(slideshow => (
                                                <option key={slideshow.id} value={slideshow.id}>{slideshow.name}</option>
                                            ))}
                                        </select>
                                    )}
                                    <button className="appdevice-btn-delete" onClick={() => handleDeleteClick(device.id)}>Delete</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ConfirmationModal2
                isOpen={isModalOpen}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                title="Confirm Deletion"
                message="Are you sure you want to delete this device?"
            />
        </div>
    );
}

export default EditAppDevicePage;
