import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SlideItem from './SlideItem';
import './EditSlideshowModal.css';

function EditSlideshowModal({ slideshow, onClose, onSave }) {
    const [slides, setSlides] = useState(slideshow.slides || []);
    const [slideshowName, setSlideshowName] = useState(slideshow.name);
    const [fadeDuration, setFadeDuration] = useState(slideshow.fade_duration || 1);

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${slideshow.id}/slides/`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = await response.json();
                
                if (response.ok) {
                    setSlides(data);
                } else {
                    console.error("Error fetching slides:", data);
                }
            } catch (error) {
                console.error("Error fetching slides:", error);
            }
        };
        
        fetchSlides();
    }, [slideshow.id]);

    const handleSaveChanges = async () => {
        console.log("Slides before saving:", slides);
        try {
            for (let index = 0; index < slides.length; index++) {
                const slide = slides[index];
                await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slides/${slide.id}/`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        duration: slide.duration,
                        image_url: slide.image_url,
                        order: index,
                        start_date: slide.start_date,
                        end_date: slide.end_date,
                    })
                });
            }

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slideshows/${slideshow.id}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: slideshowName,
                    profile: slideshow.profile,
                    slides: slides.map(slide => slide.id),
                    fade_duration: fadeDuration
                })
            });
            const data = await response.json();
            if (response.ok) {
                console.log("Slideshow updated successfully:", data);
                return data;
            } else {
                console.error("Error updating slideshow:", data);
            }
        } catch (error) {
            console.error("Error updating slideshow:", error);
        }
    };

    const handleSave = async () => {
        const updatedSlideshow = await handleSaveChanges();
        if (onSave) {
            onSave(updatedSlideshow);
        }
        onClose();
    };

    const handleAddSlide = async () => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg,image/jpg,image/png,video/mp4'; // Restrict file types
        fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'video/mp4'];
    
            if (file && allowedTypes.includes(file.type)) {
                const formData = new FormData();
                formData.append('file', file);
    
                try {
                    const uploadResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/upload_file/`, {
                        method: 'POST',
                        body: formData,
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
    
                    const jsonResponse = await uploadResponse.json();
    
                    if (uploadResponse.ok) {
                        const now = new Date();
                        const oneMonthFromNow = new Date(new Date().setMonth(now.getMonth() + 1));
                        const timezoneOffset = now.getTimezoneOffset() * 60000;
                        const localNow = new Date(now - timezoneOffset);
                        const localOneMonthFromNow = new Date(oneMonthFromNow - timezoneOffset);
                        const start_date = localNow.toISOString().slice(0, -1);
                        const end_date = localOneMonthFromNow.toISOString().slice(0, -1);
    
                        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/create_slide/`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                slideshow_id: slideshow.id,
                                order: slides.length,
                                start_date,
                                end_date,
                                image_url: jsonResponse.fileURL
                            })
                        });
    
                        const newSlide = await response.json();
    
                        if (response.ok) {
                            setSlides(prevSlides => [...prevSlides, { ...newSlide, image_url: jsonResponse.fileURL }]);
                        } else {
                            console.error("Error creating slide:", newSlide);
                        }
                    } else {
                        console.error("Error uploading file:", jsonResponse);
                    }
                } catch (err) {
                    console.error("Error uploading file:", err);
                }
            } else {
                alert('Please select a valid file type (JPEG, JPG, PNG, MP4).');
            }
        };
        fileInput.click();
    };
    

    const handleRemoveSlide = async (indexToRemove) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/slides/${slides[indexToRemove].id}/`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to delete the slide');
            }

            const updatedSlides = slides.filter((_, index) => index !== indexToRemove);
            setSlides(updatedSlides);
        } catch (error) {
            console.error('Error deleting slide:', error);
        }
    };

    const handleMove = (dragIndex, hoverIndex) => {
        const dragSlide = slides[dragIndex];
        const updatedSlides = [...slides];
        updatedSlides.splice(dragIndex, 1);
        updatedSlides.splice(hoverIndex, 0, dragSlide);
        setSlides(updatedSlides);
    };

    return (
        <div className="edit-modal">
            <h2>Edit Slideshow</h2>
            <div className="modal-header">
                <div className="input-group">
                    <input 
                        type="text" 
                        value={slideshowName} 
                        onChange={(e) => setSlideshowName(e.target.value)} 
                        placeholder="Slideshow Name"
                    />
                </div>
                <div className="input-group single-digit">
                    <label>Fade Duration (s):</label>
                    <input 
                        type="number"
                        value={fadeDuration}
                        onChange={(e) => setFadeDuration(e.target.value)}
                        min="0"
                    />
                </div>
                <button className="slideshow-modal-button add-slide" onClick={handleAddSlide}>Add Slide</button>
            </div>
            <DndProvider backend={HTML5Backend}>
                <div className="slide-container">
                    {slides.map((slide, index) => (
                        <SlideItem 
                            slide={slide} 
                            index={index} 
                            key={slide.id}
                            onMove={handleMove}
                            onSlideChange={(updatedSlide) => {
                                const updatedSlides = [...slides];
                                updatedSlides[index] = updatedSlide;
                                setSlides(updatedSlides);
                            }}
                            onRemove={() => handleRemoveSlide(index)}
                        />
                    ))}
                </div>
            </DndProvider>
            <div className="modal-footer">
                <button className="slideshow-modal-button save" onClick={handleSave}>
                    <i className="fa fa-floppy-o" aria-hidden="true"></i> Save
                </button>
                <button className="slideshow-modal-button close" onClick={onClose}>
                    <i className="fa fa-times" aria-hidden="true"></i> Close
                </button>
            </div>
        </div>
    );
}

export default EditSlideshowModal;
