import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useState, useEffect } from 'react';
import './DraggableDeal.css';


const DraggableDeal = ({ deal, dealInfo, index, handleDealChange, categoryIndex, moveDeal, onDelete }) => {
    const ref = React.useRef(null);

    const [isHovered, setIsHovered] = useState(false);

    const [, drag] = useDrag({
        type: 'DEAL',
        item: { id: deal.discountId, index, categoryIndex }
    });


    const [, drop] = useDrop({
        accept: 'DEAL',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            // Check if the deal belongs to the same category
            if (item.categoryIndex !== categoryIndex) {
                return;  // Do nothing if the deal is from a different category
            }

            if (dragIndex === hoverIndex) {
                return;
            }

            // Move the item
            moveDeal(dragIndex, hoverIndex);

            // Update the index for dragged item directly to avoid expensive index searches
            item.index = hoverIndex;

        }
    });

    drag(drop(ref));

    const getBackgroundColor = (daysSupply) => {
        if (daysSupply >= 10) {
            return 'green';
        } else if (daysSupply <= 0) {
            return 'red';
        } else {
            const greenRatio = daysSupply / 10;
            const redRatio = 1 - greenRatio;
            const greenValue = Math.floor(255 * greenRatio);
            const redValue = Math.floor(255 * redRatio);
            return `rgb(${redValue}, ${greenValue}, 0)`;
        }
    };

    const getEmoji = (dealInfo) => {
        if (dealInfo) {
            if (dealInfo.DaysSupply <= 3) {
                return '⚠️ ';
            } else if (dealInfo.UsedWeek < 5) {
                return '❄️ ';
            }
        }
        return '';
    };

    const getStatusDisplay = () => {
        // Check specifically for the presence of 'DaysSupply' to decide if the deal is considered 'expired'
        if (!dealInfo || dealInfo.DaysSupply === undefined || dealInfo.DaysSupply === null) {
            return "💀 EXPIRED - ";
        }
        // If 'ExpirationStatus' exists, use it as before
        if (dealInfo.ExpirationStatus) {
            return isHovered ? dealInfo.ExpirationStatus + ' - ' : dealInfo.ExpirationStatus.charAt(0);
        }
        return ''; // Default return if none of the above conditions are met
    };
    


    if (deal.isDutchieDeal) {
        const firstCharOfStatus = dealInfo && dealInfo.ExpirationStatus ? dealInfo.ExpirationStatus.charAt(0) : ''
        return (
            <div 
                ref={ref}
                key={deal.discountId} 
                className="linked-deal flex-container" 
                style={{
                    position: 'relative',
                    backgroundColor: dealInfo ? getBackgroundColor(dealInfo.DaysSupply) : 'grey',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                
                {getEmoji(dealInfo)}{getStatusDisplay()}{deal.description}
                {!dealInfo && (
                    <div className="loading-wave">
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                        <div className="loading-bar"></div>
                    </div>
                )}
    
                {/* Display "x" button for isDutchieDeal deals that don't have dealInfo */}
                {1 && (
                    <button 
                        onClick={() => onDelete(deal)}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            background: 'red',
                            color: 'white',
                            border: 'none',
                            borderRadius: '50%',
                            width: '20px',
                            height: '20px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textAlign: 'center'
                        }}
                    >
                        <i class="fa fa-times" aria-hidden="true"></i>

                    </button>
                )}
    
                {dealInfo && (
                    <>
                        <div className="hover-info">{dealInfo.DaysRunning} Days Active | ${dealInfo.AvgCost.toFixed(2)} Average Cost</div>
                        <div className='dealInfo flex-item'>{dealInfo.DaysSupply} Day Supply ({dealInfo.DiscountSupply} Ct) | Hits/wk: {dealInfo.UsedWeek}</div>
                        
                    </>
                )}
            </div>
        );
    } else {
        return (
            <input 
                className='manual-deal'
                ref={ref}
                key={index} 
                placeholder="Deal" 
                value={deal.description || ''}
                onChange={(e) => handleDealChange(e, categoryIndex, index)}
            />
        );
    }
};

export default DraggableDeal;