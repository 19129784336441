import React, { useState, useEffect, useRef } from 'react';
import QRCode from 'qrcode.react';
import './QRCodeManager.css';
import ConfirmationModal from './ConfirmationModal';
import { useNavigate } from 'react-router-dom';
import AppHeader from './AppHeader';

import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);







const QRCodeManager = () => {
  const [link, setLink] = useState('');
  const [qrCodes, setQrCodes] = useState([]);
  const [newLink, setNewLink] = useState('');
  const [newName, setNewName] = useState('');
  const [selectedQrCode, setSelectedQrCode] = useState(null);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [qrCodeToDelete, setQrCodeToDelete] = useState(null);

  const [showChart, setShowChart] = useState(false);
  const [chartData, setChartData] = useState({});
  const [viewChartForQrCode, setViewChartForQrCode] = useState(null);

  const [searchTerm, setSearchTerm] = useState(''); // State to handle search term
  const [filteredQRCodes, setFilteredQRCodes] = useState([]); // State to store filtered QR codes

  const navigate = useNavigate();

  const qrRef = useRef({});

  const downloadQRCode = (qrCode) => {
    const svgElement = qrRef.current[qrCode.id];
    if (!svgElement || !svgElement.querySelector('svg')) {
        console.error('SVG element not found', qrCode.id);
        return;
    }

    const svg = svgElement.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svg);
    const svgBlob = new Blob([svgData], {type: "image/svg+xml"});
    const svgUrl = URL.createObjectURL(svgBlob);

    // Create a link and trigger the download
    const link = document.createElement('a');
    link.href = svgUrl;
    link.download = `${qrCode.name || 'QRCode'}.svg`; // Set the download filename
    document.body.appendChild(link); // Add the link to the document
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up and remove the link
};


  // Function to prepare data for the pie chart
  const prepareChartData = (scannedCities) => {
    const labels = Object.keys(scannedCities);
    const data = Object.values(scannedCities);
    return {
      labels,
      datasets: [
        {
          label: 'Scan Count by City',
          data: data,
          backgroundColor: [
            // Add as many colors as you need
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            // ... more colors
          ],
        },
      ],
    };
  };

  const toggleView = (qrCodeId) => {
    if (viewChartForQrCode === qrCodeId) {
      // Toggle the chart visibility for the same QR code
      setShowChart(!showChart);
    } else {
      // Show the chart for a different QR code
      setViewChartForQrCode(qrCodeId);
      setShowChart(true); // Ensure the chart is shown when a different QR code is selected
    }
  };
  
  const chartRef = useRef(null);

const createChart = (qrCode) => {
  const chartData = prepareChartData(qrCode.scanned_cities);
  const ctx = document.getElementById(`chart-${qrCode.id}`).getContext('2d');

  if (chartRef.current) {
    chartRef.current.destroy();
  }

  chartRef.current = new Chart(ctx, {
    type: 'pie',
    data: chartData,
    options: {
      maintainAspectRatio: true, // This ensures the chart maintains its aspect ratio
      responsive: true, // Adjusts the chart size based on the parent container
      // Additional options can be added here
    }
  });
};

useEffect(() => {
    if (viewChartForQrCode !== null && showChart) {
      const qrCode = qrCodes.find(qr => qr.id === viewChartForQrCode);
      if (qrCode) {
        createChart(qrCode);
      }
    }
  }, [viewChartForQrCode, showChart, qrCodes]);
  

  useEffect(() => {
    fetchQRCodes();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const filtered = qrCodes.filter(
        (qrCode) =>
          (qrCode.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
          (qrCode.link || '').toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredQRCodes(filtered);
    } else {
      setFilteredQRCodes(qrCodes);
    }
  }, [searchTerm, qrCodes]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const SS = JSON.parse(localStorage.getItem('selectedStore'));
  const SSID = SS.id;
  console.log(SSID);

  const fetchQRCodes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/get-user-qr-codes/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Selected-Store-Id': `${SSID}`
        }
      });
      const data = await response.json();
      setQrCodes(data);
    } catch (error) {
      console.error('Error fetching QR codes', error);
    }
  };





  const createQRCode = async () => {
    try {
      const formattedLink = link.startsWith('http://') || link.startsWith('https://') ? link : 'http://' + link;
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/create-qr-code/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Selected-Store-Id': `${SSID}`,
        },
        body: JSON.stringify({ link: formattedLink, name: '' }), // Default name to blank
      });
      const data = await response.json();
      setQrCodes((prevQrCodes) => [...prevQrCodes, data]);
      setLink('');
    } catch (error) {
      console.error('Error creating QR code', error);
    }
  };

  const updateQRCode = async (qrCodeId) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/update-qr-code-link/${qrCodeId}/`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Selected-Store-Id': `${SSID}`
        },
        body: JSON.stringify({ new_link: newLink, new_name: newName })
      });
      const updatedQrCodes = qrCodes.map(qrCode =>
        qrCode.id === qrCodeId ? { ...qrCode, link: newLink, name: newName } : qrCode
      );
      setQrCodes(updatedQrCodes);
      setSelectedQrCode(null);
      setNewLink('');
      setNewName('');
      console.log('QR Code updated successfully');
    } catch (error) {
      console.error('Error updating QR Code', error);
    }
  };

  const handleDeleteQRCode = async (qrCodeId) => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/delete-qr-code/${qrCodeId}/`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'Selected-Store-Id': `${SSID}`
        }
      });
      setQrCodes(prevQrCodes => prevQrCodes.filter(qr => qr.id !== qrCodeId));
      setConfirmationModalOpen(false);
      console.log('QR Code deleted successfully');
    } catch (error) {
      console.error('Error deleting QR Code', error);
    }
  };

  const handleExit = () => {
    navigate('/dashboard');
  };
  

  return (
    <div className='background-wrapper'>
      <AppHeader
            showBackButton={true}
            showSettings={true}
            showLogout={true}
            logoUrl="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png"
        />
    <div className="qr-code-manager">
      <h1 className='QR-Page-Title'>QR Code Manager</h1>
      <div className="create-form">
        <input
          className='qr-input'
          type="text"
          placeholder="Enter link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
        />
        <button className="qr-button" onClick={createQRCode}>
            Create Qr Code <i class="fa fa-qrcode" aria-hidden="true"></i>
        </button>
      </div>
      <div className="create-form">
        <input
          className='qr-input'
          type="text"
          placeholder="Search QR Code by name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <p>Click a QR Code To Download as a SVG</p>
      <div className="qr-codes-container">
        {filteredQRCodes.map(qrCode => (
          <div key={qrCode.id} className="qr-code-container">
            <button className="btn-delete-qr" onClick={() => {
            setQrCodeToDelete(qrCode);
            setConfirmationModalOpen(true);
            }}>
                <i class="fa fa-trash-o" aria-hidden="true"></i>
            </button>
            {viewChartForQrCode === qrCode.id && showChart ? (
                <canvas id={`chart-${qrCode.id}`} style={{ width: '128px', height: '128px' }}></canvas>
            ) : (
              <div className="qr-code" onClick={() => downloadQRCode(qrCode)}
                    ref={el => qrRef.current[qrCode.id] = el}>
                <QRCode
                  value={qrCode.static_url}
                  size={128}
                  renderAs="svg"
                />
                <i className="fa fa-download qr-download-icon" aria-hidden="true"></i>
                </div>
            )}
            <button className="qr-button" onClick={() => toggleView(qrCode.id)}>
            {viewChartForQrCode === qrCode.id && showChart ? "Hide Chart" : "View Chart"}
            </button>
            

            {selectedQrCode === qrCode.id ? (
              <div className="edit-form">
                <input
                  type="text"
                  placeholder="Enter name"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Enter new link"
                  value={newLink}
                  onChange={(e) => setNewLink(e.target.value)}
                />
                <button className='qr-button top-space' onClick={() => updateQRCode(qrCode.id)}>Update QR Code</button>
              </div>
            ) : (
              <div>
                <p>Name: {qrCode.name}</p>
                <p>Link: {qrCode.link}</p>
                <button className="qr-button top-space" onClick={() => {
                  setSelectedQrCode(qrCode.id);
                  setNewLink(qrCode.link);
                  setNewName(qrCode.name || '');
                }}>
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
                </button>
              </div>
            )}
            <p>Scan Count: {qrCode.scan_count}</p>
          </div>
        ))}
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setConfirmationModalOpen(false)}
        onConfirm={() => handleDeleteQRCode(qrCodeToDelete.id)}
        title="Confirm Deletion"
        message="Are you sure you want to delete this QR code?"
        />
    </div>
    </div>
    
  );
};

export default QRCodeManager;