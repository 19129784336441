import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import ImageSlider from './ImageSlider';
import ImageSlider2 from './ImageSlider2';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import WaitList from './WaitList';
import CarouselComponent from './CarouselComponent';
import gsap from 'gsap';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';

ScrollMagicPluginGsap(ScrollMagic, gsap);

function HomePage() {
  const navigate = useNavigate();
  
  const bubblesData = [
    { header: 'Save With Your Screens', subtext: ['Enhance Your Customers Experience and','Turn Your Screens into Sales', 'Save Time, Save on Payroll 💸'] },
    { header: 'Streamline Your SOP', subtext: ['📡Remotely Control Your Digital Signage', '🎯Centralize Campaign Tracking', '🔃Bulk Asset Creation/Export'] },
    { header: 'Automate Reporting', subtext: ['Get your data where you need it.', 'Auto-Email📩Reorder & Discount Reporting'] },
    { header: 'Sync With Your POS', subtext: ['Link your Menu & Images, Discounts,', 'And Customer Queue'] }
  ];

  const handleEnter = ({ target }) => {
    gsap.fromTo(target, { autoAlpha: 0, x: '-=100' }, { autoAlpha: 1, x: '0', duration: 2 });
  };

  const handleLeave = ({ target }) => {
    gsap.to(target, { autoAlpha: 0, x: '+=100', duration: 2 });
  };

  useEffect(() => {
    const controller = new ScrollMagic.Controller();
  
    document.querySelectorAll('.bubble-container').forEach((bubble, index) => {
      const direction = index % 2 === 0 ? '-=' : '+=';
      const timeline = gsap.timeline()
        .fromTo(bubble, { autoAlpha: 0, x: `${direction}88` }, { autoAlpha: 1, x: '0', duration: 1 });
  
      new ScrollMagic.Scene({
        triggerElement: bubble,
        triggerHook: 0.6,
        reverse: false,
      })
      .setTween(timeline)
      .addTo(controller);
    });

    // Mission Statement Animation
  const missionTimeline = gsap.fromTo('.mission-statement', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 });
  new ScrollMagic.Scene({
    triggerElement: '.mission-logo-section',
    triggerHook: 0.6, // Trigger at 80% of the viewport height
    offset: '0%', // You can adjust this value as needed
  })
  .setTween(missionTimeline)
  .addTo(controller);

  // Vision Statement Animation
  const visionTimeline = gsap.fromTo('.vision-statement', { autoAlpha: 0 }, { autoAlpha: 1, duration: 1 });
  new ScrollMagic.Scene({
    triggerElement: '.mission-statement',
    triggerHook: 0.6, // Trigger at 80% of the viewport height
    offset: '0%', // You can adjust this value as needed
  })
  .setTween(visionTimeline)
  .addTo(controller);

}, []);

const handleViewPlan = () => {
    navigate('/pricing');
  }
const [isYearly, setIsYearly] = useState(false);

// Setting up individual pricing and features for each plan
const [plans, setPlans] = useState([
    {
        name: 'Enterprise',
        monthlyPrice: '$499',
        yearlyPrice: '$399',
        features: [
            'Advanced Reorder Reporting',
            'Live Customer Queue Line',
            'NextGen Dealsheet Creator',
            { feature: 'Remote Digital Signage', detail: 'unlimited screens' },
            'QR Generator & Tracker',
            'Digital Customer Facing Menu'
        ]
    },
    {
        name: 'Premium',
        monthlyPrice: '$299',
        yearlyPrice: '$199',
        features: [
            'Live Customer Queue Line',
            'NextGen Dealsheet Creator',
            { feature: 'Remote Digital Signage', detail: '5 screens' },
            'QR Generator & Tracker',
            'Digital Customer Facing Menu'
        ]
    },
    {
        name: 'Basic',
        monthlyPrice: '$99',
        yearlyPrice: '$79',
        features: [
            'Digital Customer Facing Menu',
            'QR Generator & Tracker',
            { feature: 'Remote Digital Signage', detail: '2 screens' },
            { feature: 'NextGen Dealsheet Creator', detail: 'LIMITED', limited: true }
        ]
    }
    
]);

  const htmlCode = `
  <div id="mc_embed_shell">
  <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css">
<style type="text/css">
    #mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px; max-width: 95vw; padding: 20px; border-radius:20px;}
    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
       We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
</style>
<div id="mc_embed_signup">
<form action="https://studio.us21.list-manage.com/subscribe/post?u=6db5e789fac0d3cc3bfb8363a&amp;id=7b9b8ae35b&amp;f_id=005be4e6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
    <div id="mc_embed_signup_scroll">
    <h2>Dont miss our next wave of Onboarding!</h2>
    <p>And get an EXCLUSIVE DISCOUNT, expected Q1 2024🚀<p>
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
        <div class="mc-field-group"><label for="mce-EMAIL">Email Address <span class="asterisk">*</span></label><input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required="" value=""><span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span></div>
        <div class="mc-field-group"><label for="mce-COMPANY">Company </label><input type="text" name="COMPANY" class=" text" id="mce-COMPANY" value=""></div>
    <div id="mce-responses" class="clear foot">
        <div class="response" id="mce-error-response" style="display: none;"></div>
        <div class="response" id="mce-success-response" style="display: none;"></div>
    </div>
<div style="position: absolute; left: -5000px;" aria-hidden="true">
    /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
    <input type="text" name="b_6db5e789fac0d3cc3bfb8363a_7b9b8ae35b" tabindex="-1" value="">
</div>
    <div class="optionalParent">
        <div class="clear foot">
            <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Notify Me">
            
        </div>
    </div>
</div>
</form>
</div>
<script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script></div>

  `;



  return (
    <div className='home-container'>
      <div className="content">
      <div className="background-image-container wl-conts" style={{ position: 'relative' }}>
        
        <img src='https://d27oebqltxtb0s.cloudfront.net/DemoMonitorDashboard.png' alt="Background" />
        <div className="homepage-form-container" dangerouslySetInnerHTML={{ __html: htmlCode }} />
        <div className="down-container">
          <div className="scroll"></div>
        </div>
      </div>
        <div className="mission-logo-section">
            <div className="mission-logo-container">
                <img src="https://d27oebqltxtb0s.cloudfront.net/GreenMindSimpleLogo.png" alt="Logo" />
            </div>
            <p className="mission-statement">Revolutionizing cannabis retail, we provide sophisticated software for seamless operations, enhanced customer experiences, and strategic inventory management, driving business success and growth.</p>
            <p className="vision-statement">Our vision is to...</p>
        </div>
        <div className="bubbles-section">
          {bubblesData.map((bubble, index) => (
            <div key={index} className={`bubble-container ${index % 2 === 0 ? 'left' : 'right'}`}>
              <div className="circle">
                <img src={`https://d27oebqltxtb0s.cloudfront.net/homepage-vector-${index + 1}.png`} alt={`Circle ${index + 1}`} />
              </div>
              <div className="text-container">
                <h3 className='bigger-text'>{bubble.header}</h3>
                {bubble.subtext.map((line, lineIndex) => (
                  <p key={lineIndex} className='bullets'>{line}</p>
                ))}
              </div>
              
            </div>
          ))}
        </div>
        <div className='frontend-slide-container'>
        <div className='deck-image'>
              <img src='https://d27oebqltxtb0s.cloudfront.net/Page-8.png' />
            </div>
            <ImageSlider2 className='homeimageslider' />
            <div className='text-container centered-text-container'>
                <h3 className='bigger-text'>Customer Queue Line</h3>
                <p className='bullets'>Optimize Customer Flow In-Store</p>
                <p className='bullets'>Avoid Corrals, Lines, and Confusion</p>
                <p className='bullets'>Remotely View Your Store Queues</p>
                <p className='bullets'>Customize Visually To Your Brand</p>
                <p className='bullets'>Easy Setup With Your POS</p>
                <p className='bullets'>Fast & Responsive</p>
            </div>
        </div>
        <div className='frontend-slide-container-black'>
            <div className='text-container centered-text-container-left'>
                <h3 className='bigger-text'>QR Code Generator & Tracker</h3>
                <p className='bullets'>Centralize Your Campaigns</p>
                <p className='bullets'>Track Your Scans, See Whats Working</p>
                <p className='bullets'>Dynamic Links - Change Destination After Printing</p>
                <p className='bullets'>Perfect for Lighters, Bags, Mailers, Billboards, & More!</p>
                <p className='bullets'>Location Tracking By City</p>
                <p className='bullets'>COMING SOON - Folders</p>
                
            </div>
            <CarouselComponent />
            
        </div>
        <div className='frontend-slide-container'>
        <div className='deck-image'>
              <img src='https://d27oebqltxtb0s.cloudfront.net/Page-10.png' />
            </div>
            <ImageSlider className='homeimageslider' />
            <div className='text-container centered-text-container'>
            <h3 className='bigger-text'>Customer Facing Menu</h3>
                <p className='bullets'>Live Product Menu - Touchscreen Compatable</p>
                <p className='bullets'>Sort Products By Category or Brand</p>
                <p className='bullets'></p>
                <p className='bullets'>Customize Visually To Your Brand</p>
                <p className='bullets'>Easy Kiosk Mode Installation</p>
                <p className='bullets'>Restrict Screen Features While Open</p>
            </div>
            
        </div>
        <div className='frontend-slide-container-black'>
        <div className='deck-image'>
              <img src='https://d27oebqltxtb0s.cloudfront.net/Page-12.png' />
            </div>
        </div>
        <div className='frontend-slide-container-black'>
        <div className='deck-image'>
              <img src='https://d27oebqltxtb0s.cloudfront.net/Page-3.png' />
            </div>
        </div>
        <div className='frontend-slide-container-black'>
        <div className='deck-image'>
              <img src='https://d27oebqltxtb0s.cloudfront.net/Page-2.png' />
            </div>
        </div>
        </div>
    </div>
  );
}

export default HomePage;
