import React, { useEffect, useState } from 'react';
import './ActualMarginModal.css'; // Ensure you create and style this CSS file

const ActualMarginModal = ({ isOpen, onClose, product, authHeader }) => {
  const [actualMargin, setActualMargin] = useState(null);
  const [daysOfHistory, setDaysOfHistory] = useState(7); // State to track the number of days for transaction history

  const fetchActualMargin = async (days) => {
    if (isOpen && product) {
      setActualMargin('Loading...');

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/calculate-actual-margin/`, {
          method: 'POST',  // Change to POST method
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Selected-Store-Id': JSON.parse(localStorage.getItem('selectedStore')).id
          },
          body: JSON.stringify({
              sku: product.sku,
              receivedDate: product.receivedDate,
              packageId: product.packageId,
              productId: product.productId,
              daysOfHistory: days  // Send the number of days for transaction history
            })
        });

        if (!response.ok) {
          const errorData = await response.json();
          setActualMargin(errorData.message || 'Error fetching actual margin');
          return;
        }

        const data = await response.json();
        setActualMargin(`Actual Margin: ${data.actual_margin.toFixed(2)}%`);
      } catch (error) {
        console.error('Error fetching actual margin:', error);
        setActualMargin('Error fetching actual margin');
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setDaysOfHistory(7);  // Reset to 7 days each time the modal is opened
      fetchActualMargin(7);  // Fetch data for 7 days when the modal is opened
    }
  }, [isOpen, product, authHeader]);

  const extendTo14Days = () => {
    setDaysOfHistory(14);
    fetchActualMargin(14);
  };

  const calculateSinceReceived = () => {
    const receivedDate = new Date(product.receivedDate);
    const currentDate = new Date();
    const daysSinceReceived = Math.ceil((currentDate - receivedDate) / (1000 * 60 * 60 * 24));
    setDaysOfHistory(daysSinceReceived);
    fetchActualMargin(daysSinceReceived);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="actual-margin-modal-container">
      <div className="actual-margin-modal-content">
        <h2>Actual Margin for</h2>
        <h3>{product?.product}</h3>
        <p>SKU: {product?.productId}</p> {/* Display SKU instead of Product ID */}
        <p>Received Date: {product?.receivedDate}</p>
        <p>Received Margin: {product?.gp.toFixed(2)}%</p> {/* Add this line for received margin */}
        <p className="actual-margin">{actualMargin}</p>
        <p>Based on {daysOfHistory} days of transaction history</p>
        <div className="actual-button-container">
          {daysOfHistory === 7 && (
            <button onClick={extendTo14Days}>Extend to 14 Days</button>
          )}
          {daysOfHistory === 14 && (
            <button onClick={calculateSinceReceived}>Calculate Since Received</button>
          )}
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
};

export default ActualMarginModal;
